import React, { useEffect, createContext, useState, useCallback } from "react"

import Layout from "./Layout"
import Seo from "./Seo";//TODO - set title below

import 'typeface-roboto'

import { App } from "@capacitor/app";

import Pwa from './Pwa/';
import IDANativeBridge from "./IDANativeBridge";

const GlobalContext = createContext({
    player: null,
    setPlayer: () => { }
});

const Wrapper = ({ children, ...props }) => {
    useEffect(() => {
        App.addListener('backButton', () => {
            window.history.back();

        });
    });

    const [player, setPlayer] = useState(null);
    return (
        <GlobalContext.Provider value={{ player, setPlayer }}>
            <Layout {...props}>
                <IDANativeBridge {...props}>
                    <Pwa {...props} />
                    <Seo title={`${props.entity?.title || props.entity?.identifier || 'Home'}`} />
                    {children}
                </IDANativeBridge>
            </Layout>
        </GlobalContext.Provider>
    )
}


export default Wrapper;

export { GlobalContext };
