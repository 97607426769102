import React from "react";
import { styled } from '@mui/material/styles';
import _, { before } from "lodash";

import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Menu from "../wma-list/menu";

import { getEntityUrl, getOverlayImages } from "../../util";
import Wrapper from "../../components/Wrapper";
import "./wma-hp.css"

import "core-js/proposals/string-match-all";//polyfill for old Androids; TODO - move away from here


const Root = styled('div')(({ theme }) => ({
    height:'-webkit-fill-available',
    height: "100vh",
    textTransform:"none"
}));
Root.defaultProps = {
    "data-id": "Root"
  };

const BackgroundImage = styled(BgImage)(({ theme }) => ({
    flexGrow: 0,
    width: '100%',
    height: '-webkit-fill-available',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
}));

BackgroundImage.defaultProps = {"data-id": "BackgroundImage"};

const Logo = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 18,
    left: 18,
    background: 'url(/images/logo-plg.png) no-repeat',
    display: 'none',
    backgroundSize: 'contain',
    height: 90,
    aspectRatio: '2 / 1',
    color: 'transparent',
    "@media (min-width:768px)": {
    	top: 25,
        left: 35,
        height: 110,
	},
}));
Logo.defaultProps = {"data-id": "Logo"};

const Claim = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 181,
    left: 16,
    height: 60,
    background: 'url(/images/audioguide.png) no-repeat',
    color: 'transparent',
}));

const Children = styled('div')(({ theme }) => ({
    marginTop: 'auto',
    marginLeft: '3%',
    marginBottom: 20,
    width: '94%',
    "@media (min-width:768px)": {
    	marginLeft: 30,
    marginBottom: 60,
	},
}));

const ChildLink = styled(Link)(({ theme }) => ({
    background: '#1898d6',
    backdropFilter: 'blur(2px)',
    padding: '10px 8px',
    paddingRight: 35,
    border: '1px solid #56565624',
    marginTop: 8,
    display: 'block',
    borderRadius: 0,
    width: 'fit-content',
    textDecoration: 'none',
    color: '#ffffff',
    fontSize: 20,
    fontWeight: 'bold',
    position: 'relative',
    textTransform: 'uppercase',
    "@media (min-width:768px)": {
    	fontSize: 26,
        marginTop: 11,
	},
}));

const ChildDiv = styled('div')(({ theme }) => ({
}));

const EntityPage = (props) => {

    const { entity, pageContext, selectedModule, files } = props;
    const { strapiChildren: children } = entity;

    const image = getImage(entity.largeImage?.localFile);
    const { defaultLocale } = pageContext;

    return <Root className="wma-hp">
        <Wrapper className="wrapper" {...props} menu={Menu} title={entity.title} transparentAppbar={true}>
            <div className="wrapper2" style={{ overflowX: 'hidden' }/*TODO - var*/}>
                <BackgroundImage className="background"  image={image}>
                    <Logo>{entity.title}</Logo>
                    <Claim className="claim______">{entity.shortDesc}</Claim>
                    <Children className="linkliste" >
                        {children.map((child, n) => {
                            const url = getEntityUrl({ entity: child, defaultLocale });

                            return <ChildLink className="childlink___" to={url}>{child.title}</ChildLink>
                        })}
                    </Children>
                </BackgroundImage>

            </div>
        </Wrapper>
    </Root>
}

export default EntityPage;
